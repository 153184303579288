import URL_ROUTE from '../constants/index';

class Api {
    async buscarCertificados(nit, certificado) {
        let data = {
            nit: nit != '' ? nit : '-', certificado: certificado != '' ? certificado : '-'
        }
        const query = await fetch(`${URL_ROUTE.BUSCARCERTIFICADOS}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

}

export default new Api();
